/* eslint-disable max-len */
import React from 'react';

const Virus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="COVID-19 virus icon"
    height="30"
    width="30"
    viewBox="0 0 512 512"
  >
    <title>COVID-19 virus</title>
    <desc>icon</desc>
    <path
      className="st0"
      d="M483.1,227.6h-21.5c-50.6,0-75.9-61.3-40.1-97.1l15.2-15.3c10.7-11.5,10-29.5-1.4-40.2
      c-10.9-10.2-27.8-10.2-38.7,0l-15.2,15.2c-35.8,35.8-96.9,10.5-96.9-40.2V28.4C284.4,12.7,271.7,0,256,0
      c-15.7,0-28.4,12.7-28.4,28.4V50c0,50.7-61.1,76.1-96.9,40.2L115.5,75C104,64.3,86.1,64.9,75.4,76.4c-10.2,10.9-10.2,27.8,0,38.8
      l15.2,15.3c35.8,35.8,10.4,97.1-40.1,97.1H28.9C13.2,227.3,0.3,239.8,0,255.5s12.2,28.7,27.9,29c0.4,0,0.7,0,1.1,0h21.5
      c50.6,0,75.9,61.3,40.1,97.1l-15.2,15.2c-10.6,11.6-9.9,29.6,1.7,40.2c10.9,10,27.6,10,38.5,0l15.2-15.3
      c35.8-35.8,96.9-10.5,96.9,40.2v21.5c0,15.7,12.7,28.5,28.4,28.5c15.7,0,28.4-12.7,28.4-28.5V462c0-50.7,61.1-76.1,96.9-40.2
      l15.2,15.2c11.5,10.6,29.5,9.9,40.1-1.7c10-10.9,10-27.6,0-38.5l-15.2-15.2c-35.8-35.8-10.4-97.1,40.1-97.1h21.5
      c15.7,0.3,28.6-12.2,28.9-27.9c0.3-15.7-12.2-28.7-27.9-29C483.8,227.6,483.4,227.6,483.1,227.6L483.1,227.6z M224.1,272
      c-26.5,0-47.9-21.5-47.9-48s21.4-48,47.9-48s47.9,21.5,47.9,48S250.5,272,224.1,272z M303.9,328c-13.2,0-24-10.7-24-24
      s10.7-24,24-24s24,10.7,24,24S317.1,328,303.9,328z"
    />
    <path
      className="st1"
      d="M272,224.2c0,26.5-21.4,48-47.9,48s-47.9-21.5-47.9-48s21.4-48,47.9-48S272,197.7,272,224.2z"
    />
  </svg>
);

export default Virus;
